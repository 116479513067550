import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, styled } from '@material-ui/core';
import {
  MdDeleteOutline,
  MdEventBusy,
  MdOutlineCreate,
  MdOutlineFormatListBulleted,
} from 'react-icons/md';
import moment from 'moment';
import clsx from 'clsx';

import Wrapper from '../../contenedores/Wrapper';

import CustomDialog from '../../componentes/CustomDialog';
import CustomSelect from '../../componentes/Select';
import Label from '../../componentes/Label';
import SearchBox from '../../componentes/SearchBox';
import TarjetaListado from '../../componentes/TarjetaListado';
import Typography from '../../componentes/Typography';

import BotonAgregar from '../Temporada/components/BotonAgregar';

import axios from '../../configuraciones/axios';
import endpoints, { SITIOS, TEMPORADAS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import { estatusTemporada as enumEstatus } from '../../constantes/estatus';
import { generarMapaEstatico } from '../../utilidades/functions';


import styles, { BotonIcono, CampoDescripcion } from './styles';

const Mapa = styled('img')({
  height: 190,
  aspectRatio: '9/14',
  borderRadius: 8,
  objectFit: 'cover',
});

const Temporadas = ({ history, location }) => {
  const classes = styles();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [datos, setDatos] = useState({
    sitios: [],
    temporadas: [],
  });
  const [temporadaId, setTemporadaId] = useState({
    eliminacion: null,
    finalizacion: null,
  });

  const consultarDatos = useCallback(async () => {
    try {
      const promesas = [
        await axios.get(endpoints.base.busqueda(SITIOS)),
        await axios.get(endpoints.base.busqueda(TEMPORADAS), {
          params: Object.fromEntries(query),
        }),
      ];
      const [sitios, temporadas] = await Promise.all(promesas);

      setDatos({
        sitios: [
          { id: '', nombre: 'Todos' },
          ...sitios,
        ],
        temporadas,
      });
    } catch { }
  }, [query]);

  const handleChangeSelect = useCallback((e) => {
    const { name, value } = e.target;

    if (value === null || value === '' || value === undefined) query.delete(name);
    else query.set(name, value);

    history.push({ search: query.toString() });
  }, [history, query]);

  const viajar = useCallback((id, esFormulario = true) => () => {
    const rutaPrincipal = '/temporadas';
    const rutaSecundaria = esFormulario ? 'formulario' : 'detalles';
    const ruta = id ? `${rutaPrincipal}/${rutaSecundaria}/${id}` : `${rutaPrincipal}/${rutaSecundaria}`;
    history.push(ruta);
  }, [history]);

  const abrirConfirmacion = useCallback((configuracion) => () => {
    setTemporadaId((prev) => ({
      ...prev,
      ...configuracion
    }));
  }, []);

  const eliminarTemporada = useCallback(async () => {
    try {
      await axios.delete(endpoints.base.url(TEMPORADAS, temporadaId.eliminacion));
      await consultarDatos();
    } catch { }
    abrirConfirmacion({ eliminacion: null })();
  }, [abrirConfirmacion, consultarDatos, temporadaId.eliminacion]);

  const finalizarTemporada = useCallback(async () => {
    try {
      await axios.post(endpoints.finalizarTemporada(), { temporadaID: temporadaId.finalizacion });
      await consultarDatos();
    } catch { }
    abrirConfirmacion({ finalizacion: null })();
  }, [abrirConfirmacion, consultarDatos, temporadaId.finalizacion]);

  useEffect(() => {
    consultarDatos();
  }, [consultarDatos]);

  return (
    <Wrapper classes={{ main: classes.wrapper }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" gridGap={40}>
        <Typography component="h2">
          Listado de temporadas
          <Typography variant="body1" component="small" className={classes.small}>
            {datos.temporadas.length}
            {' '}
            elemento(s)
          </Typography>
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={10}>
          <SearchBox
            name="q"
            placeholder="Buscar por nombre"
            className={clsx(classes.input, classes.search)}
          />
          <CustomSelect
            label="Sitios"
            options={datos.sitios}
            className={clsx(classes.input, classes.select)}
            name="sitio"
            valueProp="id"
            labelProp="nombre"
            value={Number(query.get('sitio')) || ''}
            onChange={handleChangeSelect}
            placeHolder="Todos"
          />
          <CustomSelect
            label="Estatus"
            options={Object.values(enumEstatus)}
            className={clsx(classes.input, classes.select)}
            placeHolder="Todos"
            labelProp="nombre"
            name="estatus"
            value={query.get('estatus') || ''}
            onChange={handleChangeSelect}
          />
          <BotonAgregar onClick={viajar()} />
        </Box>
      </Box>
      <Box className={classes.grid}>
        {
          datos.temporadas.map(({
            id,
            nombre,
            sitio,
            fechaInicio,
            fechaFin,
            cantidadInvernaderos,
            cantidadNaves,
            centro,
            coordenadas,
            tieneCapturas,
            estatus: habilitado,
          }) => {
            const fechaInicioMoment = moment(fechaInicio).startOf('day');
            const fechaFinMoment = moment(fechaFin).endOf('day');
            const fechaActual = moment();
            const duracionEnDias = Math.max(fechaFinMoment.diff(fechaActual, 'days'), 0);
            const estatus = { nombre: '', color: '' };

            if (moment().isAfter(fechaFinMoment)) {
              estatus.nombre = enumEstatus.finalizada.nombre;
              estatus.color = paleta.estatusTemporadas.finalizada;
            } else if ((fechaActual.isSameOrAfter(fechaInicioMoment) && fechaActual.isSameOrBefore(fechaFinMoment)) || tieneCapturas) {
              estatus.nombre = enumEstatus.enCurso.nombre;
              estatus.color = paleta.estatusTemporadas.enCurso;
            } else {
              estatus.nombre = enumEstatus.proxima.nombre;
              estatus.color = paleta.estatusTemporadas.proxima;
            }

            return (
              <TarjetaListado key={`temporada_${id}`} id={id}>
                <TarjetaListado.Cuerpo>
                  <Box display="flex" justifyContent="space-between" alignItems="center" pb={habilitado ? 2 : 1} pr={1}>
                    <Box display="flex" gridGap={4} flexWrap="wrap">
                      {!habilitado && (
                        <Label background={paleta.estatusTemporadas.disabled}>
                          Desactivada
                        </Label>
                      )}
                      <Label background={estatus.color}>
                        {estatus.nombre}
                      </Label>
                    </Box>
                    {(estatus.nombre === enumEstatus.enCurso.nombre) && (
                      <Typography className={classes.small}>
                        {duracionEnDias === 0 && 'Finaliza hoy'}
                        {duracionEnDias === 1 && `Queda ${duracionEnDias} día`}
                        {duracionEnDias > 1 && `Quedan ${duracionEnDias} días`}
                      </Typography>
                    )}
                  </Box>
                  <Typography className={classes.titulo}>{nombre}</Typography>
                  <CampoDescripcion label="Sitio" valor={sitio} />
                  <Box display="flex" gridGap={12} width="100%">
                    <CampoDescripcion label="Invernaderos" valor={cantidadInvernaderos} />
                    <Typography>|</Typography>
                    <CampoDescripcion label="Naves" valor={cantidadNaves} />
                  </Box>
                  <CampoDescripcion label="Duración" valor={`${fechaInicioMoment.format('DD/MM/YYYY')} - ${fechaFinMoment.format('DD/MM/YYYY')}`} />
                </TarjetaListado.Cuerpo>

                <TarjetaListado.Panel>
                  <Mapa
                    src={generarMapaEstatico({ centro, coordenadas })}
                    alt={`Mapa del sitio ${nombre}`}
                    loading="lazy"
                  />
                </TarjetaListado.Panel>

                <TarjetaListado.Accion onClick={viajar(id, false)}>
                  <BotonIcono label="Ver Detalles" Icono={MdOutlineFormatListBulleted} />
                </TarjetaListado.Accion>

                {!tieneCapturas && (
                  <TarjetaListado.Accion onClick={viajar(id)}>
                    <BotonIcono label="Editar" Icono={MdOutlineCreate} />
                  </TarjetaListado.Accion>
                )}

                {!tieneCapturas && (
                  <TarjetaListado.Accion onClick={abrirConfirmacion({ eliminacion: id })}>
                    <BotonIcono label="Eliminar" Icono={MdDeleteOutline} />
                  </TarjetaListado.Accion>
                )}

                {estatus.nombre === enumEstatus.finalizada.nombre && (
                  <TarjetaListado.Accion onClick={abrirConfirmacion({ finalizacion: id })}>
                    <BotonIcono label="Cerrar Temporada" Icono={MdEventBusy} />
                  </TarjetaListado.Accion>
                )}
              </TarjetaListado>
            );
          })
        }
        {
          datos.temporadas.length === 0 && (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" mt={6}>
              <Typography component="h2" variant="h6" style={{ fontWeight: 900 }}>
                No se encontraron temporadas
              </Typography>
            </Box>
          )
        }
      </Box>
      <CustomDialog
        titulo="Eliminar temporada"
        descripcion="Esta acción eliminará permanentemente la temporada seleccionada. ¿Estás seguro de que deseas proceder? Esta acción no se puede deshacer."
        abierto={Boolean(temporadaId.eliminacion)}
        onAceptar={eliminarTemporada}
        onCancelar={abrirConfirmacion({ eliminacion: null })}
      />
      <CustomDialog
        titulo="Finalizar temporada"
        descripcion="Al finalizar la temporada, esta pasará al histórico y no se podrá modificar. ¿Estás seguro de que deseas proceder?"
        abierto={Boolean(temporadaId.finalizacion)}
        onAceptar={finalizarTemporada}
        onCancelar={abrirConfirmacion({ finalizacion: null })}
      />
    </Wrapper>
  )
}

export default Temporadas;
