import { useState, useEffect, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import Tarjeta from '../Tarjeta';

import axios from '../../configuraciones/axios';
import { CAPTURA_POLINIZACION } from '../../configuraciones/endpoints';

import {
  setMonitoreoActivo,
  setNavesDetectadas,
  clearNavesDetectadas,
  clearMonitoreoActivo
} from '../../ducks/tablero';
import semaforo from '../../constantes/semaforos';

const TableroPolinizaciones = () => {
  const dispatch = useDispatch();
  const { sitioID, monitoreoActivo, navesEnSitio, semana } = useSelector(
    store => store.tablero,
    (prev, next) => {
      return (
        isEqual(prev.navesEnSitio, next.navesEnSitio) &&
        prev.sitioID === next.sitioID &&
        prev.monitoreoActivo.polinizacion === next.monitoreoActivo.polinizacion &&
        prev.semana === next.semana
      );
    }
  );
  const [naveEstados, setNaveEstados] = useState(null);

  const onClick = useCallback(() => {
    const { naves } = naveEstados;
    if ( !monitoreoActivo.polinizacion ) {
      const navesDetectadas = naves.map((nave) => {
        const { naveID, estado, porcentajeEfectivo } = nave;
        const detalles = `Efectividad: ≈${porcentajeEfectivo}% (${estado || 'Sin estado'})`;
        return { id: naveID, estado, extraInfo: detalles };
      });

      batch(() => {
        dispatch(setNavesDetectadas(navesDetectadas));
        dispatch(setMonitoreoActivo({ id: null, polinizacion: true }));
      })
    } else {
      batch(() => {
        dispatch(clearNavesDetectadas());
        dispatch(clearMonitoreoActivo());
      })
    };
  }, [naveEstados, monitoreoActivo.polinizacion, dispatch]);

  useEffect(() => {
    if ( sitioID && semana ) {
      const fechaObjetivo = moment(semana, 'W (YYYY)')
        .startOf('isoWeek')
        .format('YYYY-MM-DD');

      axios.get(`${CAPTURA_POLINIZACION}/tablero`, {
        params: { sitioID, fechaObjetivo }
      })
      .then((naves) => {
        const estados = navesEnSitio.map((nave) => {
          const estado = naves.find(item => item.naveID === nave.id && item.estado);
          return estado ? estado : {id: nave.id, estado: semaforo.indefinido};
        });

        const progreso = [
          {
            id: semaforo.verde,
            color: semaforo.verde,
            valor: estados.filter(item => item.estado?.toUpperCase() === semaforo.verde).length,
            orden: estados.filter(item => item.estado?.toUpperCase() === semaforo.verde).length,
          },
          {
            id: semaforo.amarillo,
            color: semaforo.amarillo,
            valor: estados.filter(item => item.estado?.toUpperCase() === semaforo.amarillo).length,
            orden: estados.filter(item => item.estado?.toUpperCase() === semaforo.amarillo).length,
          },
          {
            id: semaforo.rojo,
            color: semaforo.rojo,
            valor: estados.filter(item => item.estado?.toUpperCase() === semaforo.rojo).length,
            orden: estados.filter(item => item.estado?.toUpperCase() === semaforo.rojo).length,
          },
          {
            id: semaforo.indefinido,
            color: semaforo.indefinido,
            valor: estados.filter(item => item.estado?.toUpperCase() === semaforo.indefinido).length,
            orden: estados.filter(item => item.estado?.toUpperCase() === semaforo.indefinido).length,
          },
        ];
        progreso.sort((a, b) => b.orden - a.orden);

        setNaveEstados({title: 'Polinización', semana: fechaObjetivo, naves, progreso});
      })
      .catch();
    }

    return () => {
      batch(() => {
        dispatch(clearNavesDetectadas());
        dispatch(clearMonitoreoActivo());
      });
    }
  }, [navesEnSitio, sitioID, semana, dispatch]);

  if ( !naveEstados ) return null;
  return (
    <Tarjeta data={naveEstados} onClick={onClick} activo={monitoreoActivo.polinizacion} />
  )
}

export default TableroPolinizaciones;
